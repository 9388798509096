import { Box, Button, Typography } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import LoginHeader from '../header';
import Footer from '../footer';
import { RegistrationContext } from '../context/registrationProvider';
import { HighlightOffOutlined } from '@mui/icons-material';


const PaymentRejected = () => {

    const navigate = useNavigate();
    const { canAccessStepRegistration, allowStepRegistrationAccess, userData, setUserData } = useContext(RegistrationContext);

    const handleContinue = () => {
        allowStepRegistrationAccess();
        setUserData({
            idAccountBraintree: userData.idAccountBraintree,
            nome: userData.nome,
            cognome: userData.cognome,
            email: userData.email,
            password: btoa(userData.password),
            incarico: userData.incarico,
            dataNascita: userData.dataNascita,
            cellulare: userData.cellulare,
            confirmPassword: '',
            idBraintree: userData.idAccountBraintree,
            externalId: userData.externalId,
            idAccount: userData.idAccount
        })
        navigate('/register/choice/plane');
    };
    useEffect(() => {
        if (!canAccessStepRegistration) {
            navigate('/login');
        }
    }, [canAccessStepRegistration, navigate]);

    return (
        <>
            <LoginHeader isRegister={true} />
            <Box component="form" noValidate sx={{
                overflowX: 'hidden',
                overflowY: 'hidden',
                minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
            }}>
                <Box sx={{ flexGrow: 1, textAlign: 'center', fontFamily: 'Ruffina' }}>
                    <Typography variant="body2" align="center" sx={{ color: 'gray', mb: 3, fontFamily: 'Ruffina' }}>
                        Passaggio 3 di 3
                    </Typography>
                    <HighlightOffOutlined sx={{ color: '#DE000F', fontSize: 40, mb: 2 }} />
                    <Typography variant="h4" align="center" gutterBottom sx={{ fontFamily: 'Ruffina', maxWidth: '30%', mx: 'auto' }}>
                        Pagamento non riuscito
                    </Typography>

                    <Typography variant="body1" align="center" sx={{ color: 'gray', mb: 3, fontFamily: 'Ruffina', maxWidth: '28%', mx: 'auto' }}>
                        Ti invitiamo a verificare i dati inseriti e a riprovare.
                        Se il problema persiste,contatta la tua banca
                        o il fornitore della carta. 
                    </Typography>

                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleContinue}
                            sx={{
                                color: '#000000DE', background: '#FDC800', marginRight: '20%', padding: '8px 16px',
                                fontSize: '0.875rem'
                            }}
                        >
                            TORNA AL PAGAMENTO
                        </Button>
                    </Box>
                </Box>

                <Footer />
            </Box>
        </>
    );
};

export default PaymentRejected;
