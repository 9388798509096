import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { TextField, Button, Box, CircularProgress, Grid, MenuItem, IconButton } from '@mui/material';
import userService from '../services/userservice';
import { useAuth } from '../context/authContext';
import incaricoService from '../services/incaricoservice';
import ErrorDialog from '../errorDialog';
import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';

const EditUser = ({ externalKey, onBack }) => {
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [nome, setNome] = useState('');
    const [dataDiNascita, setDataDiNascita] = useState('');
    const [cellulare, setCellulare] = useState('');
    const [nomeIncarico, setIncarico] = useState('');
    const [email, setEmail] = useState('');
    const [incarichi, setIncarichi] = useState([]);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(true);
    const { token } = useAuth() || {};
    const [messageError, setMessageError] = useState('');
    const [titleDialog, setTitleDialog] = useState('');
    const [openDialogError, setOpenDialogError] = useState(false);
    const { saveUser, saveToken } = useAuth() || {};
    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState('');
    const [username, setUsername] = useState('');
    const [tipoAbbonamento, setTipoAbbonamento] = useState('');
    const [dataInizioAbbonamento, setDataInizioAbbonamento] = useState('');
    const [dataFineAbbonamento, setDataFineAbbonamento] = useState('');
    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const data = { externalKey };
                const response = await userService.userDetail(data, token);

                setUser(response);
                setNome(response.nome || '');
                setDataDiNascita(response.dataDiNascita || '');
                setCellulare(response.phone || '');
                setIncarico(response.incarico || '');
                setEmail(response.email || '');
                setPassword(atob(response.password) || '');
                setUsername(response.username || '');
                setTipoAbbonamento(response.abbonamento || '');
                setDataInizioAbbonamento(response.inizioAbbonamento.split('T')[0] || '');
                setDataFineAbbonamento(response.fineAbbonamento || '');
                console.log(dataFineAbbonamento);
                console.log(dataInizioAbbonamento);
            } catch (error) {
                console.error('Errore durante il fetch dei dati utente:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchUserData();
    }, [externalKey, token]);


    const validatePassword = (password, username) => {
        const specialChars = /[!#$%&()*+,\-.:;=?@^_]/;
        const lengthValid = password.length >= 8 && password.length <= 30;
        const upperCaseValid = /[A-Z]/.test(password);
        const lowerCaseValid = /[a-z]/.test(password);
        const numberValid = /\d/.test(password);
        const specialCharValid = specialChars.test(password);

        const containsUsernamePart = (password, username) => {
            if (!username) return false;
            const minLength = 3;
            for (let i = 0; i <= username.length - minLength; i++) {
                const substring = username.slice(i, i + minLength);
                if (password.includes(substring)) return true;
            }
            return false;
        };

        const usernameIncluded = containsUsernamePart(password, username);

        if (!lengthValid) return 'La password deve contenere tra 8 e 30 caratteri.';
        if (!upperCaseValid) return 'La password deve contenere almeno una lettera maiuscola.';
        if (!lowerCaseValid) return 'La password deve contenere almeno una lettera minuscola.';
        if (!numberValid) return 'La password deve contenere almeno un numero.';
        if (!specialCharValid) return 'La password deve contenere almeno un carattere speciale.';
        if (usernameIncluded) return 'La password non può contenere l\'username o parti di esso.';

        return null;
    };


    useEffect(() => {
        const getIncarichi = async () => {
            try {
                const response = await incaricoService.getAll();
                setIncarichi(Array.isArray(response.data) ? response.data : []);
            } catch (error) {
                console.error('Errore durante il fetch degli incarichi:', error);
                setIncarichi([]);
            }
        };

        getIncarichi();
    }, []);

    const handleBack = () => {
        if (onBack) {
            onBack();
        }
    };
    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!validateForm()) return;

        try {
            const updatedUser = {
                ...user,
                nomeIncarico,
                incarico: 1,
                externalKey: externalKey,
                phone: cellulare,
                email: email,
                password: btoa(password),
                username: username,
                endDate: dataFineAbbonamento
            };

            const response = await userService.updateUser(updatedUser, token);

            if (response.statusCodeValue == 202) {
                setTitleDialog('Success');
                setMessageError(response.message);
                setOpenDialogError(true);
            }

        } catch (error) {
            const tempErrors = {};

            if (error && error.response.data.statusCodeValue === 401) {
                saveToken(null);
                saveUser(null, false);
                navigate('/login')
            }

            if (error && error.response.data.message === "L'email e' gia' esistente") {
                tempErrors.email = error.response.data.message;
                setErrors(tempErrors);
            } else {
                setMessageError(error.response.data.message);
                setTitleDialog('Attenzione');
                setOpenDialogError(true);
            }
        }
    };

    const validateForm = () => {
        const tempErrors = {};
        const phonePattern = /^[0-9]{9,11}$/;
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const user = email != '' && email != null ? email : username;
        const passwordPattern = validatePassword(password, user);
        if (!nomeIncarico) {
            tempErrors.incarico = "L'incarico è obbligatorio.";
        }

        if (!email && !username) {
            tempErrors.email = "L'email è obbligatoria, se non inserisci lo username";
        } else if (!emailPattern.test(email) && !username) {
            tempErrors.email = "L'email non è valida";
        }

        if (!username && !email) {
            tempErrors.username = "Lo username è obbligatorio, se non inserisci la mail"
        }

        if (!cellulare) {
            tempErrors.cellulare = "Il cellulare è obbligatorio.";
        } else if (!phonePattern.test(cellulare)) {
            tempErrors.cellulare = "Il cellulare deve può contenere minimo 9 massimo 11 caratteri";
        }

        if (passwordPattern != null) {
            tempErrors.password = passwordPattern;
        }

        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
    };

    if (loading) {
        return <CircularProgress />;
    }

    const handleCancelErrorExit = () => {
        setOpenDialogError(false);
    };

    const formatDataToItalian = (data) => {
        if (!data) return '';

        try {

            const date = new Date(data);

            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();

            return `${day}/${month}/${year}`;


        } catch (error) {
            console.error(error);
        }
    }

    return (
        <Box component="form" noValidate sx={{ width: '100%', mt: 3 }} onSubmit={handleSubmit}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Nome/Cognome"
                        disabled
                        value={nome}
                        inputProps={{ maxLength: 50 }}
                        InputLabelProps={{
                            shrink: true,
                            style: { fontSize: '16px', color: '#666', top: '5px' },
                        }}
                        error={!!errors.nome}
                        helperText={errors.nome}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Tipo Abbonamento"
                        disabled
                        value={tipoAbbonamento == 'ANNUAL' ? 'Annuale' : tipoAbbonamento == 'FREE' ? 'Gratuito' : 'Trimestrale'}
                        inputProps={{ maxLength: 50 }}
                        InputLabelProps={{
                            shrink: true,
                            style: { fontSize: '16px', color: '#666', top: '5px' },
                        }}
                        error={!!errors.nome}
                        helperText={errors.nome}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Data Inizio Abbonamento"
                        disabled
                        value={formatDataToItalian(dataInizioAbbonamento)}
                        inputProps={{ maxLength: 50 }}
                        InputLabelProps={{
                            shrink: true,
                            style: { fontSize: '16px', color: '#666', top: '5px' },
                        }}
                        error={!!errors.nome}
                        helperText={errors.nome}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Data Fine Abbonamento"
                        disabled={tipoAbbonamento !== 'FREE' && user.isDeleted == 1}
                        value={dataFineAbbonamento}
                        onChange={(e) => {
                            const nuovaDataFine = e.target.value;

                            const isValidDate = (dateString) => {
                                const date = new Date(dateString);
                                return date instanceof Date && !isNaN(date.getTime()) && date.getFullYear() > 1900;
                            };

                            if (!isValidDate(nuovaDataFine)) {
                                setDataFineAbbonamento(dataFineAbbonamento);
                                return;
                            }

                            if (new Date(nuovaDataFine) <= new Date(dataInizioAbbonamento)) {
                                setErrors((prevErrors) => ({
                                    ...prevErrors,
                                    dataFineAbbonamento: 'La data di fine non può essere uguale o precedente alla data di inizio o non valida.',
                                }));
                                setDataFineAbbonamento(dataFineAbbonamento);


                            } else {
                                setErrors((prevErrors) => ({
                                    ...prevErrors,
                                    dataFineAbbonamento: '',
                                }));
                                setDataFineAbbonamento(nuovaDataFine);
                            }


                        }}
                        inputProps={{ maxLength: 50, min: dataInizioAbbonamento }}
                        InputLabelProps={{
                            shrink: true,
                            style: { fontSize: '16px', color: '#666', top: '5px' },
                        }}
                        error={!!errors.dataFineAbbonamento}
                        helperText={errors.dataFineAbbonamento}
                        type="date"
                    />
                </Grid>


                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Data di Nascita"
                        disabled
                        type="date"
                        value={dataDiNascita}
                        InputLabelProps={{
                            shrink: true,
                            style: { fontSize: '16px', color: '#666', top: '5px' },
                        }}
                        error={!!errors.dataDiNascita}
                        helperText={errors.dataDiNascita}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        disabled={user.isDeleted == 1}
                        fullWidth
                        select
                        label="Professione"
                        required
                        value={nomeIncarico}
                        onChange={(e) => setIncarico(e.target.value)}
                        error={!!errors.incarico}
                        helperText={errors.incarico}
                        InputLabelProps={{
                            style: { fontSize: '16px', color: '#666', top: '5px' },
                        }}
                    >
                        {incarichi.map((item) => (
                            <MenuItem key={item.idIncarico} value={item.nomeIncarico}>
                                {item.nomeIncarico}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>


                <Grid item xs={12}>
                    <TextField
                        disabled={user.isDeleted == 1}
                        fullWidth
                        label="Cellulare"
                        required
                        value={cellulare}
                        onChange={(e) => setCellulare(e.target.value)}
                        error={!!errors.cellulare}
                        helperText={errors.cellulare}
                        inputProps={{ maxLength: 10 }}
                        InputLabelProps={{
                            shrink: true,
                            style: { fontSize: '16px', color: '#666', top: '5px' },
                        }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="E-mail"
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        error={!!errors.email}
                        helperText={errors.email}
                        inputProps={{ maxLength: 50, }}
                        InputLabelProps={{
                            style: { fontSize: '16px', color: '#666', top: '5px' }
                        }}
                        sx={{
                            backgroundColor: username != null && username != '' ? '#f0f0f0' : 'transparent',
                            '& .MuiInputBase-root': {
                                backgroundColor: username != null && username != '' ? '#f0f0f0' : 'transparent',
                            }
                        }}
                        disabled={(username != null && username != '' ? true : false) || user.isDeleted == 1}

                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                       
                        fullWidth
                        label="Username"
                        required
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        error={!!errors.username}
                        helperText={errors.username}
                        inputProps={{ maxLength: 50, }}
                        InputLabelProps={{
                            style: { fontSize: '16px', color: '#666', top: '5px' }
                        }}
                        sx={{
                            backgroundColor: email != null && email != '' ? '#f0f0f0' : 'transparent',
                            '& .MuiInputBase-root': {
                                backgroundColor: email != null && email != '' ? '#f0f0f0' : 'transparent',
                            }
                        }}
                        disabled={(email != '' && email != null ? true : false) || user.isDeleted == 1}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        disabled={user.isDeleted == 1}
                        name="password"
                        label="Password"
                        type={showPassword ? 'text' : 'password'}
                        fullWidth
                        margin="normal"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        error={!!errors.password}
                        helperText={errors.password}
                        InputProps={{
                            endAdornment: (
                                <IconButton onClick={() => setShowPassword(!showPassword)}>
                                    {showPassword ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                                </IconButton>
                            ),
                        }}
                        InputLabelProps={{
                            style: { fontSize: '16px', color: '#666', top: '5px' }
                        }}
                    />
                </Grid>

            </Grid>


            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
                <Button
                    variant="contained"
                    sx={{ width: '26%', color: 'black', background: 'white', border: '1px solid black', height: '40px' }}
                    onClick={handleBack}
                >
                    INDIETRO
                </Button>
                {user.isDeleted == 1 ? <Button disabled></Button> : <Button
                    type="submit"
                    variant="contained"
                    color="warning"
                    sx={{ width: '26%', color: 'black', background: '#FDC800' }}
                    onClick={handleSubmit}
                >
                    SALVA
                </Button>

                }

            </Box>
            <ErrorDialog
                onClose={handleCancelErrorExit}
                open={openDialogError}
                title={titleDialog}
                message={messageError}
            />
        </Box>
    );
};

export default EditUser;
