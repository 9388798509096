import React, { useContext, useEffect, useRef, useState } from 'react';
import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import paymentService from '../services/paymentservice';
import LoginHeader from '../header';
import Footer from '../footer';
import '../../css/paypalButton.css'
import braintree from 'braintree-web';
import { RegistrationContext } from '../context/registrationProvider';
import { InfoOutlined } from '@mui/icons-material';

const ChoiceMethodPayment = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(true);
    const { canAccessStepRegistration, allowStepRegistrationAccess, userData, setUserData } = useContext(RegistrationContext);
    const [token, setToken] = useState('');
    const dropInInstance = useRef(null);
    const { billingPeriod, price, planName } = location.state || {};
    const isTokenFetched = useRef(false);

    useEffect(() => {
        const fetchToken = async () => {
            if (isTokenFetched.current) return;
            isTokenFetched.current = true;

            try {
                const response = await paymentService.token();
                if (response && response.data.statusCodeValue === 202) {
                    setToken(response.data.token);
                }
            } catch (error) {
                console.error("Errore durante la richiesta del token Braintree:", error);
            }
        };

        fetchToken();
    }, []);

    useEffect(() => {
        const container = document.getElementById('paypal-button');
        if (token && container) {
            clientToken();
        }
    }, [token, billingPeriod, price]);

    const loadPayPalScript = (clientId, namespace = 'paypal_sdk') => {

        return new Promise((resolve, reject) => {
            if (window[namespace]) {
                return resolve(window[namespace]);
            }

            const script = document.createElement('script');
            script.id = 'paypal-sdk';
            script.src = `https://www.paypal.com/sdk/js?client-id=${clientId}&currency=EUR`;
            script.setAttribute('data-namespace', namespace);

            script.onload = () => {
                resolve(window[namespace]);
            };

            script.onerror = () => {
                reject(new Error('Failed to load PayPal SDK'));
            };

            document.head.appendChild(script);

        });

    };

    const clientToken = async () => {
        setIsLoading(true);
        if (!token || !billingPeriod || !price) {
            console.error('Dati mancanti:', { token, billingPeriod, price });
            return;
        }
        const container = document.getElementById('paypal-button');
        container.innerHTML = '';

        const paypal = await loadPayPalScript("AQ1wQ1tzf2Tf3vBcN3MKx5BfHEwd-iLxt54WcpploDlGUSNH6Q1XDQTM-ubGlZsbaNcVGjF20AaMFxyy", "paypal_sdk");

        try {
            braintree.client.create({
                authorization: token
            }, function (clientErr, clientInstance) {
                if (clientErr) {
                    console.error('Errore nel creare il client Braintree:', clientErr);
                    return;
                }

                braintree.paypalCheckout.create({
                    client: clientInstance
                }, function (paypalCheckoutError, paypalCheckoutInstance) {
                    if (paypalCheckoutError) {
                        console.error('Errore nel creare PayPal Checkout:', paypalCheckoutError);
                        return;
                    }

                    paypalCheckoutInstance.loadPayPalSDK({
                        currency: 'EUR',
                        intent: 'capture'
                    }, function () {
                        setIsLoading(false);
                        paypal.Buttons({
                            fundingSource: paypal.FUNDING.PAYPAL,
                            createOrder: function (data, actions) {
                                return actions.order.create({
                                    purchase_units: [{
                                        amount: {
                                            value: price[0],
                                            currency_code: 'EUR'
                                        }
                                    }]
                                });
                            },
                            onApprove: (data, actions) => {
                                if (window.closed) {
                                    allowStepRegistrationAccess();
                                    navigate('/rejected');
                                    return;
                                }
                                return actions.order.capture().then(handlePaymentSuccess);
                            },
                            onCancel: function (data) {
                                allowStepRegistrationAccess();
                                navigate('/rejected');
                            },
                            onError: function (err) {
                                allowStepRegistrationAccess();
                                navigate('/rejected');
                            },
                            style: {
                                layout: 'horizontal',
                                color: 'white',
                                shape: 'rect',
                                size: 'small',
                                label: 'paypal',
                                tagline: false,
                                height: 50,
                                width: 230,
                            }
                        }).render('#paypal-button');
                    });
                });
            });
        } catch (error) {
            setIsLoading(false);
            allowStepRegistrationAccess();
            navigate('/rejected');
        }
    };

    const buttonBack = () => {
        allowStepRegistrationAccess();
        navigate('/register/choice/plane');
    }

    const handlePaymentSuccess = (details) => {
        const nonce = details.purchase_units[0].payments.captures[0].id;
        const paymentMethodId = details.purchase_units[0].payments.captures[0].id;
        const startDate = new Date();
        const formattedDate = startDate.toLocaleDateString('it-IT');
        let endDate = startDate;
        if (billingPeriod === "ANNUAL") {
            endDate = new Date(startDate);
            endDate.setFullYear(startDate.getFullYear() + 1);
        } else {
            endDate = new Date(startDate);
            endDate.setMonth(startDate.getMonth() + 3);
        }

        const formattedDateEnd = endDate.toLocaleDateString('it-IT');
        const paymentData = { nonce, billingPeriod, amount: price[0], idBraintree: userData.idAccountBraintree, planType: planName,accountId:userData.idAccount,paymentMethodId:paymentMethodId,to:userData.email,endDate:formattedDateEnd,startDate:formattedDate};

        paymentBraintree(paymentData)
            .then(response => {
                if (response && response.status === 202) {
                    allowStepRegistrationAccess();
                    navigate('/accepted');
                } else {
                    allowStepRegistrationAccess();
                    navigate('/rejected');
                }
            })
            .catch(() => navigate('/rejected'));
    };

    const paymentBraintree = async (paymentData) => {
        try {
            const response = await paymentService.payment(paymentData);
            return response;
        } catch (error) {
            console.error(error);
        }
    };
    return (
        <>
            <LoginHeader isRegister={true} />
            <Box component="form" noValidate sx={{
                overflowX: 'hidden',
                overflowY: 'hidden',
                minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                pr: 4,
                mb:8,
                mt:8
            }}>
                <Box sx={{ flexGrow: 1, textAlign: 'center',mt:18 }}>
                    <Typography variant="body2" align="center" sx={{ color: 'gray', mb: 2,mt:4, fontFamily: 'Open Sans' }}>
                        Passaggio 3 di 3
                    </Typography>
                    <Typography variant="h4" align="center" gutterBottom sx={{ fontFamily: 'Rufina', maxWidth: '30%', mx: 'auto', mb: 2,fontSize:'34px',fontWeight:'400',letterSpacing:'1px' }}>
                        Metodo di pagamento
                    </Typography>

                    <Typography variant="body1" align="center" sx={{ color: 'gray', mb: 3, fontFamily: 'Open Sans', maxWidth: '20%', mx: 'auto' }}>
                        Il pagamento è crittografato e viene utilizzato PayPal come intermediario.
                    </Typography>

                    <Box id="paypal-button" sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        border: '',
                        padding: '20px',
                        margin: '20px auto',
                        width: '402px',
                        backgroundColor: 'white',
                        cursor: 'pointer',
                        fontFamily: 'Ruffina',
                    }} />
                    {isLoading ? (
                        <CircularProgress sx={{ mt: 4, marginTop: '-25px' }} />
                    ) : (
                        <Grid item xs={12}>
                            <Box sx={{
                                backgroundColor: '#E5F6FD',
                                borderRadius: '5px',
                                p: 2,
                                mt: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                width: '430px',
                                margin: '0 auto',
                                padding: 2
                            }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                    <InfoOutlined sx={{ color: '#0587D1', mr: 1 }} />
                                    <Typography variant="subtitle2" sx={{ fontWeight: 'bold', color: '#014361', fontFamily: 'Open Sans', fontSize: '16px' }}>
                                        Come pagare con carta?
                                    </Typography>
                                </Box>
                                <Box sx={{
                                    fontSize: '14px',
                                    color: '#014361',
                                    textAlign: 'left',
                                    fontFamily: 'Open Sans',
                                    marginLeft: '29px',
                                    width: '100%'
                                }}>
                                    Per pagare con carta di credito o debito, seleziona PayPal come metodo di pagamento e segui le istruzioni. Non è necessario avere un account PayPal per completare il pagamento.
                                </Box>
                            </Box>
                        </Grid>
                    )}


                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 6 ,mb:24}}>
                        <Button
                            variant=""
                            color=""
                            onClick={buttonBack}
                            sx={{
                                color: '#000000DE', background: 'white', marginRight: '24%', padding: '8px 16px', fontSize: '0.875rem', fontFamily: 'Open Sans', border: '1px solid', fontWeight: '600', width: '101px',
                                height: '36px',
                                overflow: 'hidden'
                            }}
                        >
                            INDIETRO
                        </Button>
                    </Box>
                </Box>

                <Footer />
            </Box>
        </>
    );
};

export default ChoiceMethodPayment;
