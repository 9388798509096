import { Box, Button, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import LoginHeader from '../header';
import Footer from '../footer';
import { RegistrationContext } from '../context/registrationProvider';


const UserStartChoicePlane = () => {

    const navigate = useNavigate();
    const { canAccessStepRegistration, allowStepRegistrationAccess, userData, setUserData } = useContext(RegistrationContext);

    const handleContinue = () => {
        allowStepRegistrationAccess();
        setUserData({
            nome: userData.nome,
            cognome: userData.cognome,
            dataNascita: userData.dataNascita,
            incarico: userData.incarico,
            cellulare: userData.cellulare,
            email: userData.email,
            password: atob(userData.password),
            confirmPassword: atob(userData.password),
            externalId: userData.externalId,
            idAccount: userData.idAccount
        });
        navigate('/register/choice/plane');
    };

    const handleSubmit = () => {
        setUserData({
            nome: userData.nome,
            cognome: userData.cognome,
            dataNascita: userData.dataNascita,
            incarico: userData.incarico,
            cellulare: userData.cellulare,
            email: userData.email,
            password: atob(userData.password),
            confirmPassword: atob(userData.password),
            externalId: userData.externalId,
            idAccount: userData.idAccount
        });
           navigate('/register/login');
    };

    return (
        <>
            <LoginHeader isRegister={true}/>
            <Box component="form" noValidate sx={{
                overflowX: 'hidden',
                overflowY: 'hidden',
                minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                pr:4,
                mt:8
            }}>
                <Box sx={{ flexGrow: 1, textAlign: 'center', mb:13}}>
                    <Typography variant="body2" align="center" sx={{ color: 'gray', mb:2, fontFamily: 'Open Sans' }}>
                        Passaggio 2 di 3
                    </Typography>

                    <Typography variant="h4" align="center" gutterBottom sx={{ fontFamily: 'Rufina', maxWidth: '30%', mx: 'auto',mb:4,fontSize:'34px',letterSpacing:'1px',fontWeight:'400px' }}>
                        Scegli un piano
                    </Typography>

                    <Typography variant="body1" align="center" sx={{ color: 'gray', mb: 6, fontFamily: 'Open Sans', maxWidth: '34%', mx: 'auto', fontSize:'16px' }}>
                    Accedi all’edizione digitale dell’Annuario Pontificio, selezionando uno dei due piani di abbonamento disponibili.
                    </Typography>

                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4, mb:13}}>

                        <Button
                            variant="outlined"
                            color=""
                            onClick={handleSubmit}
                            sx={{ color: 'black', background: 'white',marginRight:'160px'}}
                        >
                            INDIETRO
                        </Button>
                        <Button
                            variant="outlined"
                            color="warning"
                            onClick={handleContinue}
                            sx={{ background: '#FDC800', color: 'black',  }}
                        >
                            CONTINUA
                        </Button>
                    </Box>
                </Box>
                <Footer sx={{mt:13}}/>
            </Box>
        </>
    );
};

export default UserStartChoicePlane;
